// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LeadModal from "../../../../styleguide/modals/LeadModal/LeadModal.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function ScheduleModalServices(props) {
  return JsxRuntime.jsx(LeadModal.make, {
              title: props.title,
              subtitle: props.subtitle,
              buttonTitle: props.buttonTitle,
              close: props.onClose,
              leadType: props.leadType
            });
}

var make = ScheduleModalServices;

export {
  make ,
}
/* LeadModal Not a pure module */
